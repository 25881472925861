import React from 'react';
import Content from '../Content';
import PropTypes from 'prop-types';

const AboutPageTemplate = ({ title, content, contentComponent, logo }) => {
  const PageContent = contentComponent || Content;

  return (
    <div>
      <section className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-one-fifth is-1'>
                <img alt='' src={logo} />
              </div>
              <div className='column is-10'>
                <div className='section'>
                  <h1 className='title'>{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h2 className='title is-3'>Predavači</h2>
                <div className='columns'>
                  <div className='column is-5'>
                    <img
                      src='/img/jelena.png'
                      width='200'
                      height='200'
                      alt=''
                    />
                    {/* <h2 className='title is-4'>dr Jelena Tekic</h2> */}
                  </div>
                  <div className='column is-5'>
                    <img src='/img/pedja.png' width='200' height='200' alt='' />
                    {/* <h2 className='title is-4'>dr Predrag Tekic</h2> */}
                  </div>
                </div>

                <PageContent
                  className='content has-text-justified'
                  content={content}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  logo: PropTypes.string,
};

export default AboutPageTemplate;
